<template lang="html">
    <div class="uk-container">
        <div class="uk-flex uk-flex-center uk-flex-middle uk-padding" uk-height-viewport>
            <div class="uk-width-medium">

                <chart :records="records"></chart>

                <form @submit.prevent="onSave" class="uk-margin">
                    <div class="uk-grid uk-grid-small">
                        <div class="uk-width-2-3">
                            <input type="date" class="uk-input uk-form-small" v-model="date">
                        </div>
                        <div class="uk-width-1-3">
                            <input type="time" class="uk-input uk-form-small" v-model="time">
                        </div>
                    </div>

                    <div class="uk-grid uk-grid-small">
                        <div class="uk-width-2-3">
                            <input type="number" class="uk-input uk-form-small" v-model="temp" min="36" max="42" step="0.01" autoload>
                        </div>
                        <div class="uk-width-1-3">
                            <select v-model="mark" class="uk-select uk-form-small">
                                <option value="0">:)</option>
                                <option value="1">.</option>
                                <option value="2">/</option>
                                <option value="3">X</option>
                            </select>
                        </div>
                    </div>

                    <div class="uk-button-group uk-flex uk-flex-center uk-margin-small">
                        <button type="button" class="uk-button uk-button-text uk-button-small uk-margin-right" @click.prevent="onCancel" v-if="isEdit">
                            <span uk-icon="icon: close; ration: .7"></span>
                        </button>
                        <button type="submit" class="uk-button uk-button-text uk-button-small uk-border-rounded" v-if="isEdit">
                            <span uk-icon="icon: check; ration: .7"></span>
                        </button>
                        <button type="submit" class="uk-button uk-button-text uk-button-small uk-border-rounded" v-else>
                            <span uk-icon="icon: plus; ration: .7"></span>
                        </button>
                    </div>
                </form>

                <div class="uk-height-small uk-overflow-auto">
                    <table class="uk-table uk-table-divider uk-table-small uk-text-small">
                        <tbody>
                            <tr v-for="(r, key) in records">
                                <td class="uk-text-right">{{ formatDate(key) }}</td>
                                <td class="uk-text-right">{{ r.temp.toFixed(2) }}&#176;</td>
                                <td>{{ r.mark }}</td>
                                <td>
                                    <a class="uk-link-reset uk-margin-small-right" href="#" @click.prevent="onEdit(key, r)" uk-icon="icon: pencil; ration: .7"></a>
                                    <a class="uk-link-reset" href="#" @click.prevent="onDelete(key)" uk-icon="icon: trash; ratio: .8"></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

var now = moment().valueOf();

export default {
    data () {
        return {
            isEdit : false,
            oKey : now,
            nKey : now,
            temp : 36.5,
            mark : 0
        };
    },
    computed : Object.assign({
            date : {
                get () {
                    if (this.nKey == "") {
                        return moment().format("YYYY-MM-DD");
                    } else {
                        return moment(parseInt(this.nKey)).format("YYYY-MM-DD");
                    }
                },
                set (v) {
                    var newDate = (v ? moment(v, "YYYY-MM-DD") : moment());

                    this.nKey = moment(parseInt(this.nKey)).date(newDate.date()).month(newDate.month()).year(newDate.year()).valueOf();
                }
            },
            time : {
                get () {
                    if (this.nKey == "") {
                        return moment().format("HH:mm");
                    } else {
                        return moment(parseInt(this.nKey)).format("HH:mm");
                    }
                },
                set (v) {
                    var newDate = (v ? moment(v, "HH:mm") : moment());

                    this.nKey = moment(parseInt(this.nKey)).minutes(newDate.minutes()).hours(newDate.hours()).valueOf();
                }
            }
        },
        mapState([
            "records"
        ])
    ),
    methods : {
        onSave () {
            this.$store.dispatch("saveRecord", {
                oKey : this.oKey,
                nKey : this.nKey,
                temp : parseFloat(this.temp),
                mark : parseInt(this.mark)
            }).then(() => {
                this.onCancel();
            });
        },
        onCancel () {
            var now = moment().valueOf();

            this.isEdit = false;
            this.oKey = now;
            this.nKey = now;
            this.temp = 36.5;
            this.mark = 0;
        },
        onEdit (key, record) {
            this.isEdit = true;
            this.oKey = key;
            this.nKey = key;
            this.temp = parseFloat(record.temp);
            this.mark = parseInt(record.mark);
        },
        onDelete (key) {
            if (confirm("Really?")) {
                this.$store.dispatch("deleteRecord", key);
            }
        },
        formatDate (key) {
            return moment(parseInt(key)).format("DD. MMM");
        }
    }
}
</script>

<style lang="css" scoped>
</style>
