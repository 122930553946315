import Vue from "vue";
import Vuex from "vuex";
import Firebase from "firebase/app";

Vue.use(Vuex);

const store = new Vuex.Store({
    state : {
        records : {}
    },
    mutations : {
        setRecords (state, records) {
            state.records = records;
        }
    },
    actions : {
        subscribeToRecords ({ commit }) {
            Firebase.database().ref("records").on("value", function (snapshot) {
                commit("setRecords", snapshot.val());
            });
        },
        deleteRecord ({ state }, key) {
            Firebase.database().ref("records/" + key).remove();
        },
        saveRecord ({ state }, payload) {
            var key = Date.now();

            if (payload.oKey) { // Update
                if (payload.oKey != payload.nKey) {
                    Firebase.database().ref("records/" + payload.oKey).remove();
                }

                key = payload.nKey;
            }

            return Firebase.database().ref("records/" + key).set({
                temp : payload.temp,
                mark : payload.mark
            });
        }
    }
});

export default store;
