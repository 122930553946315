import Vue from "vue";
import Firebase from "firebase/app";
import "firebase/database";

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import 'uikit/dist/css/uikit.min.css';

// loads the Icon plugin
UIkit.use(Icons);

/*
 *  Firebase
 */
var firebaseConfig = {
    apiKey: "AIzaSyBjEfUzCHVD_5o2rxqGB0FWo6xTk1egb9Y",
    authDomain: "pupu-36565.firebaseapp.com",
    databaseURL: "https://pupu-36565.firebaseio.com",
    projectId: "pupu-36565",
    storageBucket: "",
    messagingSenderId: "847272012133",
    appId: "1:847272012133:web:e63afa87a57ce6f2"
};
Firebase.initializeApp(firebaseConfig);

import store from "./store";
import router from "./router";

import App from "./pages/App.vue";

store.dispatch("subscribeToRecords");

/*
 *  Application
 */
const app = new Vue({
    store,
    router,
    render: h => h(App)
}).$mount("#app");
