<template lang="html">
    <div class="">
        <canvas id="temp-chart"></canvas>
        <canvas id="mark-chart" height="50"></canvas>
    </div>
</template>

<script>
import Chart from "chart.js";
import moment from "moment";

export default {
    props : [
        "records"
    ],
    data () {
        return {
            tempChart : null,
            markChart : null
        };
    },
    watch : {
        records () {
            if (!this.tempChart || !this.markChart) {
                return;
            }

            var labels = [];
            var temp = [];
            var mark = [];

            for (var key in this.records) {
                labels.push(parseInt(key));
                temp.push(this.records[key].temp);
                mark.push(this.records[key].mark);
            }

            this.tempChart.data.labels = labels;
            this.tempChart.data.datasets[0].data = temp;
            this.tempChart.update();

            this.markChart.data.labels = labels;
            this.markChart.data.datasets[0].data = mark;
            this.markChart.update();
        }
    },
    mounted () {
        this.tempChart = new Chart("temp-chart", {
            type : "line",
            data : {
                labels : [],
                datasets : [
                    {
                        data : [],
                        backgroundColor: "rgba(54, 162, 235, .5)",
                        borderColor: "rgba(54, 162, 235, 1)",
                        fill : false
                    }
                ]
            },
            options : {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            unit: 'day'
                        }
                    }],
                    yAxes: [{
                        display: false,
                        ticks: {
                            min: 36,
                            max: 37
                        }
                    }]
                }
            }
        });

        this.markChart = new Chart("mark-chart", {
            type : "bar",
            data : {
                labels : [],
                datasets : [
                    {
                        data : [],
                        backgroundColor: "rgba(255, 99, 132, .5)",
                        borderColor: "rgba(255, 99, 132, 1)",
                        borderWidth: 1
                    }
                ]
            },
            options : {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        type: 'time',
                        distribution: 'linear',
                        time: {
                            unit: 'day'
                        }
                    }],
                    yAxes: [{
                        display: false,
                        ticks: {
                            min: 0,
                            max: 3
                        }
                    }]
                }
            }
        });
    },
    beforeDestroy () {
        if (this.tempChart) {
            this.tempChart.destroy();
            this.tempChart = null;
        }

        if (this.markChart) {
            this.markChart.destroy();
            this.markChart = null;
        }
    }
}
</script>

<style lang="css" scoped>
</style>
