import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Home from "./pages/Home.vue";
/*
import Login from "./pages/Login.vue";
import Loading from "./pages/Loading.vue";
import Error404 from "./pages/Error404.vue";
*/

import Chart from "./components/Chart.vue";

Vue.component("chart", Chart);

const router = new VueRouter({
    routes :  [
        { path : "/", component : Home },
        /*
        { path : "/login", component : Login },
        { path : "/loading", component : Loading },
        { path: "*", component: Error404 }
        */
    ]
});

export default router;
